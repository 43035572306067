
import Vue from 'vue'
import Component from 'vue-class-component'
import CustomElDropdown from '@/components/CustomElements/Dropdown.vue'
import { storeAuth } from '@/store/modules/auth'

@Component({ components: { CustomElDropdown } })
export default class UserMenu extends Vue {
  async logout() {
    await storeAuth.logout()
    await this.$router.push('/login')
  }
}
