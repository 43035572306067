import Vue from 'vue'
import { orderBy } from 'lodash'
import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators'

import store from '@/store'
import { storeApp } from '@/store/modules/app'
import { IConfigurationGroup, IConfigurationsState } from '@/store/typings/configurations'

const uaConfigurationTypes = ['ua_campaign', 'ua_chronology', 'ua_creative', 'ua_manager', 'ua_producer', 'ua_tag', 'ua_tag_category']
const contentServerTypes = ['content_category', 'content_group', 'content_tag']
const grabbersTypes = ['schema_group', 'smaug_app_config', 'adjust_integration']

@Module({ dynamic: true, store, namespaced: true, name: 'configurations' })
class ConfigurationsModule extends VuexModule implements IConfigurationsState {
  _configurationGroups: IConfigurationGroup[] = []

  get configurationGroups(): IConfigurationGroup[] {
    return orderBy(this._configurationGroups, configurationGroup => configurationGroup.name.toLowerCase())
  }

  get configurationGroupsIDs(): { [configurationGroupID: string]: true } {
    return this.configurationGroups.reduce(
      (configurationGroupsIDs, configurationGroup) => ({ ...configurationGroupsIDs, [configurationGroup.id]: true }),
      {}
    )
  }

  get remoteConfigurationsList(): IConfigurationGroup[] {
    const excludedConfigurations = [...uaConfigurationTypes, ...contentServerTypes, ...grabbersTypes]
    return this.configurationGroups.filter(configurationGroup => !excludedConfigurations.includes(configurationGroup.type))
  }

  get contentServerList(): IConfigurationGroup[] {
    return this.configurationGroups.filter(configurationGroup => contentServerTypes.includes(configurationGroup.type))
  }

  get grabbersList(): IConfigurationGroup[] {
    return this.configurationGroups.filter(configurationGroup => grabbersTypes.includes(configurationGroup.type))
  }

  get uaChronologyList(): IConfigurationGroup[] {
    return this.configurationGroups.filter(configurationGroup => uaConfigurationTypes.includes(configurationGroup.type))
  }

  get currentConfiguration(): IConfigurationGroup | null {
    return this.configurationGroups.find(configurationGroup => configurationGroup.id === storeApp.resourceID) ?? null
  }

  @Mutation
  setConfigurationGroups(configurationGroups: IConfigurationGroup[]) {
    this._configurationGroups = configurationGroups
  }

  @Action
  async loadConfigurationsGroups() {
    const configurationGroups: IConfigurationGroup[] = await Vue.prototype.$api('getConfigurations')
    this.setConfigurationGroups(configurationGroups)
  }
}

export const storeConfigurations: InstanceType<typeof ConfigurationsModule> = getModule(ConfigurationsModule)
